import React from 'react';
import DefaultButton from "../components/defaultButton"
import { Row, Col } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useStaticQuery, graphql } from "gatsby"
import StylesBanner from '../styles/banner.module.scss'

const BannerComponent = () => {

  const Data = useStaticQuery(graphql`
    query {
      contentfulBanner {
        bannerTitle
        bannerCtaText
        bannerParagraph {
          json
        }
        bannerImage {
          file {
            url
            fileName
          }
        }
      }
    }
  `)

  const BannerData = Data.contentfulBanner


  return (
    <section>
      <Row className="d-flex align-items-center align-self-center invertSmallBanner">
        <Col md={5} lg={5} sm={12} className="text-center-small">
          <h1>{BannerData.bannerTitle}</h1>
          {documentToReactComponents(BannerData.bannerParagraph.json)}
          <DefaultButton
            title={BannerData.bannerCtaText}
            to="/form"
            className="mt-4 mb-4"
          />
        </Col>

        <Col md={7} lg={7} sm={12}>
          <img
            src={BannerData.bannerImage.file.url}
            alt={BannerData.bannerImage.file.fileName}
            className={StylesBanner.imageHeader}
          />
        </Col>
      </Row>
    </section>
  );
};



export default BannerComponent;

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import StyleCRM from "../styles/crm.module.scss"
import { Row, Col } from "react-bootstrap"

export default function DiscoverLogos() {
    const data = useStaticQuery(graphql`
    query {
contentfulDiscoverLogos{
    crmBrands{
        id
        title
      file{
        url
        fileName
      }
    }
  }
    }
  `)
    const discover = data.contentfulDiscoverLogos

    return (
        <div className="row justify-content-center">
            <div className="col-lg-9">
                <Row className="row justify-content-center">
                    {discover.crmBrands.map(logo => {
                        return (
                            <Col md={2} lg={2} sm={4} xs={4} key={logo.id}>
                                <img
                                    src={logo.file.url}
                                    alt={logo.title}
                                    className={StyleCRM.imageStyle}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </div>
    )
}

import React from "react"
import { Row, Col } from "react-bootstrap"
import StyleClients from "../styles/clients.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ClientsSay = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulClientsSay(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            clientSayBigTitle
            clientName
            clientWork
            image {
              file {
                url
              }
            }
            id
            clientComment {
              json
            }
          }
        }
      }
    }
  `)

  const ClientsSay = data.allContentfulClientsSay

  return ClientsSay.edges.map(client => {
    return (
      <Row key={client.node.id} className="justify-content-md-center">
        <Col lg={11} className={StyleClients.box}>
          <div className="row">
            <Col lg={12}>
              <h1 style={{ textAlign: 'center' }}>{client.node.clientSayBigTitle}</h1>
            </Col>
            <Col lg={4} xl={4} md={4} sm={12}>
              <img src={client?.node?.image?.file?.url ? client.node.image.file.url : ''} alt={client?.node?.clientName ? client.node.clientName : ''} className={`${StyleClients.image} img-fluid m-auto d-block`} />
            </Col>
            <Col lg={8} xl={8} md={8} sm={12}>
              <div className={StyleClients.comment}>
                {documentToReactComponents(client.node.clientComment.json)}
              </div>
              <div className={StyleClients.tooltip}>
                <div className={StyleClients.pConatiner}>
                  <p>{client.node.clientName}</p>
                  <p>{client.node.clientWork}</p>
                </div>
              </div>
            </Col>
          </div>
        </Col>
      </Row>
    )
  })
}

export default ClientsSay

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col } from "react-bootstrap"
import StyleFeature from '../styles/features.module.scss';

export default function FeaturesSection(props) {
  const Features = useStaticQuery(graphql`
    query {
      allContentfulFeaturesStent(sort: { fields: updatedAt, order: DESC }, filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            featuresTitle
            createdAt
            updatedAt
            featuresText {
              json
            }
            featuresImage {
              file {
                url
                fileName
              }
            }
          }
        }
      }
      allContentfulFeaturesBigContent(filter: {node_locale:{eq:"en-US"}}) {
        edges {
          node {
            featuresTitle
            featureImage {
              file {
                url
                fileName
              }
            }
            featureText {
              json
            }
          }
        }
      }
    }
  `)

  const FeatureData = Features.allContentfulFeaturesStent
  const FeatureBigContent = Features.allContentfulFeaturesBigContent

  return (
    <section style={{ padding: props.padding }}>
      <Container>
        <Row className={StyleFeature.interiorSectionPadding}>
          {FeatureData.edges.map(feature => {
            return (
              <Col lg={4} md={4} sm={12} key={feature.node.featuresTitle} className={`${StyleFeature.imageContainer} sal-disabled`}
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease-out-back">
                <div>
                  <img
                    src={feature?.node?.featuresImage?.file?.url ? feature.node.featuresImage.file.url : ''}
                    alt={feature?.node?.featuresImage?.file?.fileName ? feature.node.featuresImage.file.fileName : ''}
                    className="img-fluid"
                    style={{ maxHeight: '208px' }}
                  />
                </div>
                <div>
                  <h3 className="text-center">{feature?.node?.featuresTitle ? feature.node.featuresTitle : ''}</h3>
                  {documentToReactComponents(feature.node.featuresText.json)}
                </div>
              </Col>
            )
          })}
        </Row>

        {FeatureBigContent.edges.map(feature => {
          return (
            <Row key={feature.node.featuresTitle} className={StyleFeature.interiorSectionContent}
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease-out-back">
              <Col lg={6} md={6} sm={12}>
                <img
                  src={feature?.node?.featureImage?.file?.url ? feature.node.featureImage.file.url : ''}
                  alt={feature?.node?.featureImage?.file?.fileName ? feature.node.featureImage.file.fileName : ''}
                  className="img-fluid"
                />
              </Col>
              <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                <h2>{feature?.node?.featuresTitle ? feature.node.featuresTitle : ''}</h2>
                {documentToReactComponents(feature.node.featureText.json)}
              </div>
            </Row>
          )
        })}
      </Container>
    </section>
  )
}

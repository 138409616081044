import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

export default class HeadingTextComponent extends Component {
    render() {
        return (
            <Row className="justify-content-center">
                <Col lg={6} md={6} sm={10} className="text-center">
                    <h2 className="h1">{this.props.title}</h2>
                    {this.props.subtitle}
                </Col>
            </Row>
        )
    }
}

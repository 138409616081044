import React, { Component } from 'react'
import { Row, Container, Col } from 'react-bootstrap';
import BoxStyle from '../styles/box.module.scss';
import DefaultButton from './defaultButton';
import StyleButtonCenter from '../styles/button.module.scss';

export default class BoxCenter extends Component {
    render() {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={11} md={11} sm={12} xs={12}>
                        <div className={BoxStyle.box}>
                            <img src={this.props.imgUrl} className={BoxStyle.styleBackgroundBox} />
                            <h1 className="text-center">{this.props.title}</h1>
                            <p className="text-center mb-5" style={{ position: 'relative' }}>{this.props.subTitle}</p>
                            <div>
                                {this.props.children}
                            </div>
                            <DefaultButton
                                title={this.props.cta}
                                to="/form"
                                className={StyleButtonCenter.btnCenter}
                            />
                        </div>
                    </Col>
                </Row>
            </Container >
        )
    }
}

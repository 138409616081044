import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { Col } from 'react-bootstrap';
import StyleCRM from '../styles/crm.module.scss';

export default function CRMSection(props) {
  const Crm = useStaticQuery(graphql`
    query{
      # Crm tools
        contentfulCrmTools{
          crmTitle
          crmBrands{
            id
            title
            file{
              fileName
              url
            }
          }
        }
    }
  `)
  const CRMTools = Crm.contentfulCrmTools;

  return (
    <section style={{ padding: props.padding }}>
      <div className="row">
        <Col lg={12} md={12} sm={12}>
          <p className={StyleCRM.headding}>{CRMTools.crmTitle}</p>
        </Col>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row justify-content-center">
            {CRMTools.crmBrands.map(brand => {
              return (
                <Col md={2} lg={2} sm={6} xs={6} key={brand.id} className="d-flex align-self-center">
                  <img src={brand.file.url} alt={brand.title} className={`${StyleCRM.imageStyle} img-fluid`} />
                </Col>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

import React from "react";
import BtnStyle from '../styles/button.module.scss';
import { Link } from 'gatsby'

const DefaultButton = (props) => {

    return <Link
        className={`${BtnStyle.btnStent} ${props.className}`}
        to={props.to}
        style={props.style}>
        {props.title}
    </Link >
}

export default DefaultButton;

import React from "react"
import { useStaticQuery } from "gatsby"
import { Row, Col } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BoxCenter from "./box-center"
import StyleProcess from '../styles/process.module.scss'
import '../styles/global.scss'
import DiscoverLogos from "./discover"

const ProcessComponent = (props) => {
  const data = useStaticQuery(graphql`
    query {        
        contentfulFreeTrialBlock {
            blockTitle
            blockSubtitle
            blockCta
            blockImg{
                file{
                    url
                }
            }
        }
      allContentfulProcess(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            id
             separatorImage{
                file{
                    url
                }
            }
            #         general
            processGeneralTitle
            processGeneralContent {
              json
            }
            #         Planinng
            processPlanningTitle
            processPlanningImage {
              file {
                url
              }
            }
            processPlanningContent {
              json
            }
            #         Build
            processBuildTitle
            processBuildImage {
              file {
                url
              }
            }
            processBuildContent {
              json
            }
            #         Target
            processTargetTitle
            processTargetImage {
              file {
                url
              }
            }
            processTargetContent {
              json
            }
            #         Content
            processContentTitle
            contentCtaLink
            contentCtaText
            processContentImage {
              file {
                url
              }
            }
            processContentContent {
              json
            }
            #         Conversion

            processConversionTitle
            conversionCtaText
            conversionCtaLink
            processConversionImage {
              file {
                url
              }
            }
            processConversionContent {
              json
            }
            #         Maximize
            processMaximizeTitle
            processMaximizeImage {
              file {
                url
              }
            }
            processMaximizeContent {
              json
            }
            #         Nourish
            processNourishTitle
            processNourishImage {
              file {
                url
              }
            }
            processNourishContent {
              json
            }
          }
        }
      }
    }
  `)


  const ProcessData = data.allContentfulProcess;
  const { blockTitle, blockSubtitle, blockCta, blockImg } = data.contentfulFreeTrialBlock;

  return (
    ProcessData.edges.map(process => {
      return (
        <section key={process.node.id} style={{ padding: props.padding }} className={StyleProcess.sectionStyle}>
          <Row className="row justify-content-md-center mb-5 animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={6} md={6} className="text-center">
              <h2 className="h1">{process.node.processGeneralTitle}</h2>
              {documentToReactComponents(process.node.processGeneralContent.json)}
            </Col>
          </Row>

          {/* Planning */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.planning}>
              <Row className={StyleProcess.smallStyle}>
                <Col lg={6} md={6} xl={6}>
                  <img src={process?.node?.processPlanningImage?.file?.url ? process.node.processPlanningImage.file.url : ''} alt={process?.node?.processPlanningTitle ? process.node.processPlanningTitle : ''} className="img-fluid" />
                </Col>
                <Col lg={6} md={6} xl={6} >
                  <h3>{process?.node?.processPlanningTitle ? process.node.processPlanningTitle : ''}</h3>
                  {documentToReactComponents(process.node.processPlanningContent.json)}
                </Col>
              </Row>
            </Col>
            <img src={process?.node?.separatorImage?.file?.url ? process.node.separatorImage.file.url : ''} alt="" className={`${StyleProcess.separatorStyle} ${StyleProcess.separatorStyleInversed}`} />
          </Row>


          {/* Build */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.build}>
              <Row className={StyleProcess.smallStyle}>
                <Col lg={6} md={6}>
                  <h3>{process.node.processBuildTitle}</h3>
                  {documentToReactComponents(process.node.processBuildContent.json)}
                </Col>
                <Col lg={6} md={6}>
                  <img src={process?.node?.processBuildImage?.file?.url ? process.node.processBuildImage.file.url : ''} alt={process?.node?.processBuildTitle ? process.node.processBuildTitle : ''} className="img-fluid" />
                </Col>
              </Row>
            </Col>
            <img src={process?.node?.separatorImage?.file?.url ? process.node.separatorImage.file.url : ''} alt="" className={`${StyleProcess.separatorStyle}`} />
          </Row>


          {/* Target */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.target}>
              <Row className={StyleProcess.smallStyle}>
                <Col lg={6} md={6}>
                  <img src={process?.node?.processTargetImage?.file?.url ? process.node.processTargetImage.file.url : ''} alt={process?.node?.processTargetTitle ? process.node.processTargetTitle : ''} className="img-fluid" />
                </Col>
                <Col lg={6} md={6}>
                  <h3>{process?.node?.processTargetTitle ? process.node.processTargetTitle : ''}</h3>
                  {documentToReactComponents(process.node.processTargetContent.json)}
                </Col>
              </Row>
            </Col>
            <img src={process?.node?.separatorImage?.file?.url ? process.node.separatorImage.file.url : ''} alt="" className={`${StyleProcess.separatorStyle} ${StyleProcess.separatorStyleInversed}`} />
          </Row>


          {/* Content */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.content}>
              <Row className={StyleProcess.smallStyle}>
                <Col lg={6} md={6}>
                  <h3>{process.node.processContentTitle}</h3>
                  {documentToReactComponents(process.node.processContentContent.json)}
                  {/* <DefaultButton
                    title={process.node.contentCtaText}
                    url={process.node.contentCtaLink}
                    style={btnSmStyle}
                  /> */}
                </Col>
                <Col lg={6} md={6}>
                  <img src={process?.node?.processContentImage?.file?.url ? process.node.processContentImage.file.url : ''} alt={process?.node?.processContentTitle ? process.node.processContentTitle : ''} className="img-fluid" />
                </Col>
              </Row>
            </Col>
            <img src={process?.node?.separatorImage?.file?.url ? process.node.separatorImage.file.url : ''} className={`${StyleProcess.separatorStyle}`} />
          </Row>


          {/* Conversion */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.conversion}>
              <Row className={StyleProcess.smallStyle}>
                <Col lg={6} md={6}>
                  <img src={process?.node?.processConversionImage?.file?.url ? process.node.processConversionImage.file.url : ''} alt={process?.node?.processConversionTitle ? process.node.processConversionTitle : ''} className="img-fluid" />
                </Col>
                <Col lg={6} md={6}>
                  <h3>{process.node.processConversionTitle}</h3>
                  {documentToReactComponents(process.node.processConversionContent.json)}
                  {/* <DefaultButton
                    title={process.node.conversionCtaText}
                    url={process.node.conversionCtaLink}
                    style={btnSmStyle}
                  /> */}
                </Col>
              </Row>
            </Col>
            <img src={process?.node?.separatorImage?.file?.url ? process.node.separatorImage.file.url : ''} alt="" className={`${StyleProcess.separatorStyle} ${StyleProcess.separatorStyleInversed}`} />
          </Row>


          {/* Maximize */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.maximize}>
              <Row className={StyleProcess.smallStyle}>
                <Col lg={6} md={6}>
                  <h3>{process.node.processMaximizeTitle}</h3>
                  {documentToReactComponents(process.node.processMaximizeContent.json)}
                </Col>
                <Col lg={6} md={6}>
                  <img src={process?.node?.processMaximizeImage?.file?.url ? process.node.processMaximizeImage.file.url : ''} alt={process?.node?.processMaximizeTitle ? process.node.processMaximizeTitle : ''} className="img-fluid" />
                </Col>
              </Row>
            </Col>
            <img src={process?.node?.separatorImage?.file?.url ? process.node.separatorImage.file.url : ''} className={`${StyleProcess.separatorStyle}`} />
          </Row>


          {/* Nourish */}
          <Row className="justify-content-md-center animationBlock"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-back">
            <Col lg={8} md={8} xl={8} className={StyleProcess.nourish}>
              <Row>
                <Col lg={6} md={6}>
                  <img src={process?.node?.processNourishImage?.file?.url ? process.node.processNourishImage.file.url : ''} alt={process?.node?.processNourishTitle ? process.node.processNourishTitle : ''} className="img-fluid" />
                </Col>
                <Col lg={6} md={6}>
                  <h3>{process?.node?.processNourishTitle ? process.node.processNourishTitle : ''}</h3>
                  {documentToReactComponents(process.node.processNourishContent.json)}
                </Col>
              </Row>
            </Col>
          </Row>

          <BoxCenter
            title={blockTitle}
            subTitle={blockSubtitle}
            cta={blockCta}
            imgUrl={blockImg.file.url}
          >
            <DiscoverLogos></DiscoverLogos>
          </BoxCenter>
        </section >
      )
    })
  )
}

export default ProcessComponent

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import CRMSection from "../components/crm"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import FeaturesSection from "../components/features"
import ClientsSay from "../components/clientsSay"
import ProcessComponent from "../components/process"
import "../styles/global.scss"
import HeadingTextComponent from "../components/heading"
import loadable from '@loadable/component'
import BannerComponent from "../components/banner"

const Slide = loadable(() => import('../components/slide'))

const IndexPage = () => {
  const Data = useStaticQuery(graphql`
    query {
      contentfulKnowMore {
        title
        subtitle {
          json
        }
      }     
    }
  `)

  // const BannerData = Data.contentfulBanner
  const KnowMoreData = Data.contentfulKnowMore

  return (
    <Layout>
      {/* <Helmet>
        <script src={withPrefix('eAdvocacy.js')} type="text/javascript" />
      </Helmet> */}
      <SEO title="Unleash your full social selling potential" />

      <Container>
        <BannerComponent />
      </Container>

      <Container>
        <CRMSection></CRMSection>
      </Container>

      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <FeaturesSection padding="100px 0"></FeaturesSection>
      </Container>

      <Container>
        <ClientsSay></ClientsSay>
      </Container>

      <Container>
        <ProcessComponent></ProcessComponent>
      </Container>

      <Container>
        <HeadingTextComponent
          title={KnowMoreData.title}
          subtitle={documentToReactComponents(KnowMoreData.subtitle.json)}></HeadingTextComponent>
      </Container>

      <Container>
        <Slide />
      </Container>

    </Layout>
  )
}

export default IndexPage

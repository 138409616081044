import React from "react"
import { Container } from 'react-bootstrap';
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import StyleFooter from '../styles/footer.module.scss';

const Layout = (props) => {
  return (
    <article>
      <header>
        <Container>
          <HeaderComponent />
        </Container>
      </header>
      <main>
        {props.children}
      </main>
      <footer>
        <Container>
          <FooterComponent className={StyleFooter.box} />
        </Container>
      </footer>
    </article>
  )
}

export default Layout
